export const utilities = {
  namespaced: true,
  state: { user_ip: "" },
  actions: {
    setUserIp({ commit }, user_ip) {
      if (user_ip) {
        commit("userIdSuccess", user_ip);
      } else {
        commit("userIdFailed");
      }
    },
  },
  mutations: {
    userIdSuccess(state, user_ip) {
      state.user_ip = user_ip;
    },
    userIdFailed(state) {
      state.user_ip = "";
    },
  },
};
