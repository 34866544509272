<template>
   <nav class="navbar navbar-expand-lg navbar-light navbar_bg fixed-top">
        <div class="container">
                <router-link :to="{ name: '' }" class="text-decoration-none navbar-brand"><img src="/assets/img/logo/logo.png" style="width: 50px; height: 50px" alt="ExtremeSpeed"></router-link>
        </div>
    </nav>
</template>

<script>
export default {
  name: "HeaderVue",
};
</script>

<style scoped></style>
